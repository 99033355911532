/**
 * © Antoine Puel for Midi La Nuit — 2020
 */

/**
 * Dependencies
 */
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import lazySizes from 'lazysizes';
import Headroom from "headroom.js";
import * as basicScroll from 'basicscroll'


/**
 * Modules
 */
import * as Menu from './js/Menu';

/**
 * LazySizes Options
 */
lazySizes.cfg.lazyClass = 'lazy';

/**
 * Barba Options
 */

barba.hooks.beforeEnter((data) => {
  const currentContainer = data.next.container;
  // Go back to top when switching pages
  window.scrollTo(0, 0);
  // Remove overflow hidden from body
  document.body.style.overflow = '';
  // Instantiate Menu mobile
  const menu = currentContainer.querySelector('.menu');
  const menuMobile = currentContainer.querySelector('.menu-mobile');
  const menuDesktop = currentContainer.querySelector('.menu-desktop');
  const menuButton = currentContainer.querySelector('.menu-button');
  Menu.toggleMenu(menuButton, menuMobile, menu);
  // Instantiate Headroom
  const headroom  = new Headroom(menu, {tolerance: {up: 5, down: 5}});
  headroom.init();
});

let instance;

barba.use(barbaPrefetch);
barba.init({
  transitions: [{
    once(data) {
      // Play the fading animation at first page load
      const currentContainer = data.next.container;
      const dynamicContent = currentContainer.querySelector('.dynamic-content');
      dynamicContent.classList.add('enter');
    },
    enter(data) {
      const currentContainer = data.next.container;
      const dynamicContent = currentContainer.querySelector('.dynamic-content');
      // Wait one frame before adding the class
      setTimeout(() => dynamicContent.classList.add('enter'), 150);
    }
  }],
  views: [
    {
      namespace: 'home',
      beforeEnter({ next }) {
        const images = next.container.querySelectorAll('.featured-work-image');
        images.forEach((image) => {
          instance = basicScroll.create({
            elem: image,
            from: 'top-top',
            to: '0',
            props: {
              '--translateY': {
                from: '0',
                to: `-25vh`
                // timing: 'cubicInOut'
              }
            }
          })
          instance.start()
        })
      }
    },
    {
      namespace: 'prestation',
      afterEnter(data) {
        const images = document.querySelectorAll('.project-image');

        const config = {
          rootMargin: '-150px 0px'
        };

        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
              entry.target.classList.add('colored');
            } else {
              entry.target.classList.remove('colored');
            }
          });
        }, config);

        images.forEach(image => {
          observer.observe(image);
        });
      }
    },
  ]
});