/**
 * © Antoine Puel for Midi La Nuit — 2020
 */

const body = document.body;

/*
 * Toggle mobile menu when clicked
 */
const toggleMenu = (toggle, menuMobile, menu) => {
  if (toggle) {
    toggle.addEventListener('click', () => {
      // Disallow user to scroll when menu is open
      if (menuMobile.classList.contains('active')) {
        body.style.overflow = '';
      } else {
        body.style.overflow = 'hidden';
      }
      // Toggle menu mobile visibility
      menuMobile.classList.toggle('active');
      menu.classList.toggle('active');
      // Set correct ARIA attribute
      if (menuMobile.getAttribute('aria-hidden') === 'true') {
        menuMobile.setAttribute('aria-hidden', 'false');
      } else {
        menuMobile.setAttribute('aria-hidden', 'true');
      }
    });
  }  

  // Hide menu mobile when page is resized
  const mediaQuery = window.matchMedia('(min-width: 992px)');
  const hideMenu = (mediaQuery) => {
    if (mediaQuery.matches) {
      menuMobile.classList.remove('active');
      menu.classList.remove('active');
      body.style.overflow = '';
    }
  };
  mediaQuery.addListener(hideMenu);
};

export { toggleMenu };
